import { Head, Notes } from 'mdx-deck';
import { theme } from "../../../../../../theme";
import zeitLogo from "../../../../../../zeit-black-triangle.svg";
import React from 'react';
export default {
  Head: Head,
  Notes: Notes,
  theme: theme,
  zeitLogo: zeitLogo,
  React: React
};